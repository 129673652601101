try {
    mixpanel.init("a773e4c34f22e596a54ed41af066dbc9", {
        debug: true,
        track_pageview: "full-url",
        persistence: "localStorage",
    });
} catch (e) {}

var email = null;

window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-screen-leave', (event) => {
    // console.log('heyflow screen leave:', event.detail);
    if (event.detail.screenName == 'plan' && email != null) {
        // console.log('Email:', email);
        fbq('init', '3885121185107562', {
            em: email
        });
        fbq('track', 'Lead');
        try {
            mixpanel.people.set({
                '$email': email,
                'email': email
            });
            mixpanel.track('Updated Email', {
                'email': email
            })
        } catch (e) {}
        // console.log("Updated email");
        email = null;
    }
});

window.addEventListener('heyflow-change', (event) => {
    // console.log('heyflow change:', event);
    if (event.detail.fieldsSimple.email != null) {
        // console.log(event.detail.fieldsSimple.email);
        email = event.detail.fieldsSimple.email;
    }
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});